.view-tours:not(.view-display-id-block_3) {
  .view-content {
    @include boxShadow(large);
    background-color: color(white);
    padding: 2em 1em;
    tbody {
      .views-field-title {
        @include font(oswald, 600);
      }
      .date_column,
      .views-field-field-period-1 {
        @include font(oswald);
      }
      .views-field-field-profile a {
        color: color(gray);
        &:hover {
          color: color(blue);
        }
      }
    }
  }
}


