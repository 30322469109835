.search-results {
  padding: 0;
  .search-result + .search-result {
  border-top: 1px solid color(gray-light);
  margin-top: 1em;
  padding-top: 1em;
  }
  .search-snippet-info {
    padding: 0;
    strong {
      background-color: rgba(color(blue-lighter), .75);
      padding: 0 .25em;
    }
  }
}