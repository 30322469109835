h1 {
  font-size: $font-medium;
  line-height: 1;
  @include breakpoint($normal) {
    font-size: $font-xlarge;
  }
  &#page-title {
    @include font(oswald, 400);
    background-color: color(blue-light);
    color: color(white);
    margin: 0;
    padding: .75em 1.5em;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint($normal) {
      padding: .25em 1em;
    }
  }
}
h2 {
  @include font(oswald, 400);
  color: color(blue);
  font-size: $font-large;
  line-height: 1.1;
  text-transform: uppercase;
  @include breakpoint($medium) {
    font-size: $font-xlarge;
  }
  .block-views & {
    margin: 0;
    text-align: center;
    span:first-child {
      color: color(gray);
    }
  }
  .sidebar & {
    font-size: $font-discrete;
    font-weight: 600;
    padding: 0 .5rem;
    text-align: left;
    text-transform: none;
  }
}
h3 {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  line-height: 1.2;
}
h4 {
  font-size: 1.125em;
}
h5 {
  font-size: 0.95em;
}
h6 {
  font-size: 0.95em;
}

p {
  margin: 0.5em 0;
}

a {
  color: color(blue);
  transition: color .25s ease-in-out;
}

.result-count {
  @include font(oswald, 600);
  color: color(blue);
  font-size: $font-discrete;
  margin-bottom: .5em;
}

// Typography content.
.field-name-body {
  h2 {
    font-size: $font-discrete;
  }
  img {
    max-width: none;
  }
}