.node-news {

  // Teaser.
  &.node-teaser {
    padding: .5em;
    position: relative;
    &:hover {
      .group-image {
        @include boxShadow;
      }
      .field-name-title a {
        color: color(blue);
      }
    }
    .group-image {
      transition: box-shadow .25s ease-in-out;
    }
    & > .field-name-title {
      a {
        color: color(gray);
        font-weight: 700;
        text-decoration: none;
        &::before {
          @include blockLink;
        }
      }
    }
    .field-name-post-date,
    .field-name-field-reference h3 {
      font-size: $font-small;
      font-weight: 400;
    }
    .group-author {
      align-items: baseline;
      display: flex;
      .prefix-author {
        font-size: $font-small;
        margin-right: .25em;
      }
    }
  }

}
