.node-event {
  &.node-full {
    .node-profile.view-mode-header {
      margin: -1.5em 0 0em;
    }
    h3 {
      color: #0aa5df;
      font-size: 1.8em;
    }
    .field {
      padding: 0.6em 0;
    }
    .field-name-field-where,
    .field-name-field-date,
    .field-name-field-price,
    .field-name-field-web-link {
      border-bottom: 1px solid #ddd;
    }
    .field-name-field-price .currency {
      margin-left: .25em;
    }
    .field-label {
      @include font(oswald, 600);
      color: color(blue);
      font-size: $font-medium;
      margin-right: 1em;
    }
    .field-name-field-where {
      display: flex;
    }
  }
  &.node-teaser {
    @include boxShadow;
    background-color: color(white);
    padding: 1em 1.5em;
    position: relative;
    transition: box-shadow .25s ease-in-out;
    &:hover {
      @include boxShadowHover;
      h3 a {
        color: color(blue-dark);
      }
    }
    & > .field-name-title {
      a {
        @include font(oswald, 600);
        color: color(blue);
        text-decoration: none;
        &::before {
          @include blockLink;
        }
      }
    }
    .field-name-field-date {
      font-weight: 700;
    }
    .field-name-field-reference {
      h3 {
        font-size: $font-normal;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
