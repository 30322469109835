.h-invisible {
  opacity: 0;
  height: 0;
}
.h-visible {
  opacity: 1;
  height: auto;
}
.h-hide {
  display: none;
}
.h-show {
  display: block;
}