.view-country-guides-content {
  .view-header {
    h3 {
      @include font(oswald, 600);
      color: color(gray);
      font-size: $font-medium;
      margin-bottom: .25rem;
      margin-top: .25rem;
    }
  }
}
