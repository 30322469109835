.sidebar {
  // First.
  &.sidebar-first {
    @include breakpoint($normal) {
      order: -1;
    }
    .section {
      background-color: color(white);
      border-radius: .5em;
      padding: 1em;
      @include breakpoint($normal) {
        box-shadow: 0 1em 2em rgba(color(black),.1);
        padding: 1.5em 1em;
        -webkit-transform: translateZ(0);
      }
      ul {
        @include font(oswald);
        padding: 0;
        text-align: center;
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          & + li {
            &::before {
              background-color: color(gray);
              content: '';
              display: block;
              height: 1px;
              margin: .4em auto;
              opacity: .25;
              width: 3em;
            }
          }
          a {
            color: color(gray);
            display: block;
            margin: 0 auto;
            max-width: 95%;
            padding: .4em;
            text-decoration: none;
            &:hover {
              color: color(blue);
            }
            &.active-trail,
            &.active {
              color: color(blue);
              position: relative;
              z-index: 1;
              @include breakpoint($normal) {
                color: color(white);
                &::before {
                  background-color: color(blue);
                  content: '';
                  display: block;
                  height: 100%;
                  left: 0;
                  position: absolute;
                  right: 5px;
                  top: 0;
                  z-index: -1;
                }
                &::after {
                  background: url('../images/elements/arrow-sidebar.svg') no-repeat center center;
                  content: '';
                  display: block;
                  height: 100%;
                  position: absolute;
                  right: -5px;
                  top: 0;
                  width: 10px;
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }
  }
  // Btn sidebar.
  .btn-sidebar {
    @include font(oswald);
    background-color: color(blue);
    border-radius: .25em;
    border: 0;
    color: color(white);
    display: block;
    margin: 0 1em;
    padding: .5em .75em;
    text-align: left;
    width: calc(100% - 2em);
    @include breakpoint($normal) {
      display: none;
    }
    &.h-open + .content {
      max-height: 50em;
      opacity: 1;
      padding-top: .5em;
    }
  }
}
