.node-press-release {

  // Slide.
  &.view-mode-slide {
    position: relative;
    @include breakpoint($medium) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding: 2em;
    }
    .field-name-field-logo {
      border-radius: 1em 1em 0 0;
      overflow: hidden;
      min-height: 50vw;
      width: 100%;
      @include breakpoint($medium) {
        min-height: 40vw;
        border-radius: 1em;
        bottom: 0;
        left: 0;
        position : absolute;
        right: 0;
        top: 0;
      }
    }
    .group-content {
      color: color(white);
      padding: 1em 2em 3em;
      position: relative;
      text-align: center;
      z-index: 2;
      @include breakpoint($medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2em;
        min-height: 20em;
        padding: 1em 2em 2em;
        width: 20em;
        &::before {
          background: linear-gradient(135deg, color(blue), color(blue-light));
          border-radius: 50%;
          bottom: -2em;
          box-shadow: 0 0 2em rgba(color(black), .2);
          content: '';
          display: block;
          left: -2em;
          position: absolute;
          right: -2em;
          top: -2em;
          z-index: -1;
        }
      }
      h3 {
        @include font(oswald, 600);
        font-size: $font-discrete;
      }
      .author {
        @include font(oswald);
        color: color(gray);
      }
      .field-name-post-date {
        @include font(oswald);
        text-transform: uppercase;
      }
      .field-name-body {
        font-size: $font-small;
        line-height: 1.3;
        text-align: left;
      }
    }
    .field-name-node-link {
      a {
        @include blockLink;
        text-indent: -9999px;
        z-index: 10;
      }
    }

    // TEMP
    // @TODO: filter html in drupal
    img {
      display: none;
    }
    .field-name-body {
      strong {
        font-weight: normal;
      }
    }
  }

  // Teaser.
  &.node-teaser {
    padding: .5em;
    & > .field-name-title {
      a {
        color: color(gray);
        font-weight: 700;
        text-decoration: none;
      }
    }
    .field-name-post-date,
    .field-name-field-reference h3 {
      font-size: $font-small;
      font-weight: 400;
    }
    .author {
      font-size: $font-small;
    }
  }

  // Slide&teaser.
  &.node-teaser,
  &.view-mode-slide {
    position: relative;
    .group-image {
      transition: box-shadow .25s ease-in-out;
    }
    .field-name-title a {
      &::before {
        @include blockLink;
      }
    }
    &:hover {
      .group-image {
        @include boxShadow;
      }
      .field-name-title a {
        color: color(blue);
      }
    }
  }

  // Full.
  &.view-mode-full {
    .field-name-field-reference {
      align-items: center;
      display: flex;
      margin: -1.5em 0 2.5em;
      min-height: 2.5em;
      .field-items {
        width: 100%;
      }
      .field-name-title {
        flex-grow: 1;
        .field-item {
          align-items: center;
          display: flex;
          .field-member-flag {
            display: none;
          }
          .node-type {
            @include font(oswald, 600);
            font-size: $font-medium;
            margin-right: auto;
            text-transform: uppercase;
          }
          h2 {
            @include font(oswald, 600);
            font-size: $font-medium;
            margin: 0 .25em;
            text-transform: uppercase;
          }
        }
      }
      .field-name-field-logo {
        margin-left: auto;
        padding-left: .75em;
      }
    }
  }
}
