* {
  box-sizing: border-box;
}

body {
  @include font-smoothing(on);
  @include font(roboto, 400);
  background-color: color(gray-lighter);
  color: color(gray);
  font-size: $font-normal;
  line-height: 1.5;
  overflow-x: hidden;
  &.front {
    background-color: color(white);
  }
  &.open-menu-mobile {
    overflow: hidden;
  }
}


img,
media {
  display: block;
  max-width: 100%;
  height: auto;
}

ul,
ol {
  margin: 0;
}

ul.breadcrumb {
  display:block;
  li {
    display: inline-block;
    padding-right: 0.2em;
    a {
      display: block;
    }
  }
}

.clearfix:after {
  display: none;
}

table {
  width: 100%;
  th {
    @include font(oswald, 600);
    border: 0;
    color: color(blue);
    font-size: $font-medium;
    padding: .75em 1em;
    text-transform: uppercase;
    a {
      text-decoration: none;
    }
  }
  tbody {
    border: 0;
  }
  tr {
    &.even {
      background-color: color(white);
      border: 0;
    }
    &.odd {
      background-color: color(blue-lightest);
      border: 0;
    }
  }
  td {
    padding: .75em 1em;
    &.active {
      background-color: rgba(color(blue), .05);
    }
  }
}