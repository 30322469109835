  .view-news {
  // Front block
  &.view-display-id-block_1 {
    padding: 0 1em;
    position: relative;
    @include breakpoint($medium) {
      padding: 0;
    }
    &::before {
      background: linear-gradient(to right, color(blue), color(blue-light));
      border-top: 1px solid rgba(color(white), .25);
      height: 40vw;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include breakpoint($medium) {
        bottom: 50%;
        height: auto;
      }
    }
    .slick-dots {
      bottom: .5rem;
      left: 0;
      position: absolute;
      right: 0;
      @include breakpoint($medium) {
        bottom: 2.75rem;
        left: auto;
        right: 2rem;
        width: 28rem;
      }
      .slick-active button {
        background-color: color(white);
      }
    }
    .slick-track {
      display: flex;
      @include breakpoint($medium) {
        display: block;
      }
    }
    .slick__slide {
      background: linear-gradient(135deg, color(blue), color(blue-light));
      border-radius: 1em;
      display: flex;
      height: auto;
      justify-content: center;
      overflow: hidden;
      position: relative;
      @include breakpoint($medium) {
        align-items: center;
        background: transparent;
        display: block;
      }
      &::before {
        background: color(blue);
        content: '';
        display: block;
        height: 30em;
        opacity: .25;
        position: absolute;
        right: -22em;
        top: 2em;
        transform: scale(2) rotate(-25deg);
        width: 15em;
        z-index: 1;
        @include breakpoint($medium) {
          opacity: .6;
          right: -2em;
        }
      }
    }
    .slick-slider::after {
      background: linear-gradient(rgba(color(blue-darker), .5), rgba(color(blue-darker), 0));
      content: '';
      display: block;
      height: 30em;
      position: absolute;
      right: -22em;
      top: 2em;
      transform: scale(2) rotate(-25deg);
      width: 15em;
      z-index: -1;
      @include breakpoint($medium) {
        right: 0;
      }
    }
  }
  // Slick
  .slick {
    // @include maxWidth;
    padding-top: 1em;
    @include breakpoint($medium) {
      margin-bottom: 2em;
    }
    .slide {
      padding: 0;
      width: 25%;
    }
  }
}
