.not-front #main-wrapper {
  @include maxWidth;
  margin-top: 1em;
  @include breakpoint($normal) {
    margin-top: 2em;
  }
}
.not-front .region-after-content {
  @include maxWidth;
}

#main {
  margin: 0 -.5em;
  #content,
  .sidebar {
    padding: 0 .5em;
  }
}

#page-wrapper {
  overflow-x: hidden;
  padding-top: 4em;
  @include breakpoint($normal) {
    padding-top: 0;
  }
}

#before-content {
  width: 100%;
}

#after-content {
  background-color: color(gray-lighter);
  margin-bottom: -2em;
  padding-bottom: 2em;
  padding-top: 2em;
  .section {
    @include maxWidth;
  }
}

.not-front.page-node .region-content,
.not-front.page-user .region-content,
.not-front.page-users .region-content {
  @include boxShadow;
  background-color: color(white);
  border-radius: .5em;
  overflow: hidden;
  padding: 2em;
  .group-cover {
    margin: -2em -2em 2em;
  }
}

.one-sidebar,
.two-sidebars {
  #main {
    display: flex;
    flex-direction: column;
    @include breakpoint($normal) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .sidebar {
      flex: 1;
      min-width: 25%;
      &.sidebar-first {
        margin: 0 -5%;
        @include breakpoint($normal) {
          margin: 0;
        }
      }
    }
    #content {
      flex: 3;
      margin-bottom: 1em;
      min-width: 75%;
      @include breakpoint($normal) {
        margin-bottom: 0;
      }
      .section > .node {
        @include boxShadow(large);
        background-color: color(white);
        padding: 2em;
        margin-bottom: 2em;
        .field-name-field-featured-image {
          margin-bottom: 1em;
        }
      }
    }
  }
}

#blank-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 1000;
}

// TEMP
// @TODO: controllare filter hmtl contenuti
.content a {
  overflow-wrap: break-word;
}
