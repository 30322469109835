.header-wrapper {
  position: relative;
  z-index: 5;
  &.h-is-open {
    .header-content {
      visibility: visible;
    }
    #logo {
      opacity: 0 !important;
      visibility: hidden !important;
    }
    #block-ejn-global-user-custom-block {
      display: block;
    }
  }
  .header-content {
    background: linear-gradient(to right, color(blue), color(blue-light));
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    padding: 1em 0 10em;
    position: fixed;
    visibility: hidden;
    width: 100%;
    @include breakpoint($normal) {
      display: block;
      overflow: visible;
      height: 100%;
      padding: 0;
      position: relative;
      z-index: 30;
      opacity: 1;
      visibility: visible;
    }
    .content {
      @include maxWidth(large);
      position: relative;
    }
  }

  #header {
    order: 2;
    @include breakpoint($normal) {
      background: linear-gradient(to right, color(blue), color(blue-light));
      border-bottom: 1px solid rgba(color(white), .25);
      position: relative;
      z-index: 2;
    }
    .section {
      @include maxWidth(large);
      align-items: center;
      display: flex;
      @include breakpoint($normal) {
        min-height: 8em;
      }
    }
    .region-header {
      align-items: center;
      display: flex;
      flex-grow: 1;
      @include breakpoint($normal) {
        min-height: 8em;
      }
    }
  }

  #logo {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    @include breakpoint($normal) {
      position: relative;
    }
    &::before {
      background-color: #fff;
      box-shadow: 5px 5px 10px rgba(0,0,0,.15);
      content: '';
      display: block;
      height: 120%;
      position: absolute;
      top: 0;
      transform: rotate(-7deg) translateY(-25%);
      width: 100%;
    }
    svg {
      height: 100%;
      margin: 0.5em 1em;
      max-width: 10em;
      position: relative;
      @include breakpoint($normal) {
        margin: 1em;
        max-width: 100%;
      }
    }
  }

  #block-menu-menu-social-menu {
    flex-grow: 1;
    .content {
      @include breakpoint($normal) {
        align-items: center;
        display: flex;
        justify-content: space-around;
      }
    }
  }

  #navigation {
    -webkit-transform: translateZ(0);
    background: linear-gradient(to right, color(blue), color(blue-light));
    width: 100% !important;
    z-index: 1;
  }
}
