.node-marketplace {
  &.view-mode-teaser {
    @include font(oswald);
    background-color: color(white);
    .field-name-title {
      padding-bottom: 1em;
      padding-left: 1em;
      padding-top: 1em;
      a {
        color: color(gray);
        display: block;
        text-decoration: none;
        &:hover {
          color: color(blue);
        }
      }
    }
    .field-name-field-text {
      padding-bottom: 1em;
      padding-left: 1em;
    }
  }
  &.node-full {
    .content > h2 {
      margin-bottom: .5em;
      margin-top: 0;
    }
    .paragraphs-item-audio {
      margin-bottom: 1em;
      margin-top: 1em;
      h2 {
        @include horizontalSeparatorTitle();
        font-size: 1.75em;
        margin-bottom: .5em;
        span {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
    .paragraphs-item-images {
      margin-bottom: 1.5em;
    }
    .paragraphs-item-text-area {
      @include font(lato);
      font-weight: 300;
      margin-top: 1em;
      strong {
        @include font(oswald);
        color: color(blue);
        font-weight: 600;
      }
    }
    .paragraphs-item-video {
      margin-bottom: 1.5em;
      h2 {
        @include horizontalSeparatorTitle();
        font-size: 1.75em;
        margin-bottom: .5em;
        span {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
      .player {
        height: 0;
        padding-bottom: 56.25%;
        padding-top: 25px;
        position: relative;
      }
      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
