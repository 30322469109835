#block-views-activities-restyle-block,
#block-views-case-histories-block-4 {
  background: linear-gradient(145deg, $gradient-activities);
  padding: 2em 0;
  text-align: center;
  h2 {
    display: inline-block;
    margin-bottom: .25em;
    padding: 0 .25em;
    position: relative;
    &::after,
    &::before {
      @include sprite--title-dec;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(-100%, -50%);
    }
    &::after {
      left: auto;
      right: 0;
      transform: translate(100%, -50%);
    }
  }
  .content {
    @include maxWidth;
    .slick__slider {
      display: flex;
      flex-wrap: wrap;
      .slide {
        width: 33.3%;
      }
    }
  }
}
