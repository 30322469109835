#block-views-events-restyle-block {
  margin-bottom: 3em;
  margin-top: 3em;
  padding: 2em 0;
  h2 {
    margin-bottom: .5em;
  }
  .content {
    @include maxWidth;
    .slick__slider {
      display: flex;
      flex-wrap: wrap;
      .slide {
        width: 25%;
      }
    }
  }
}