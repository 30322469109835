.c-image-contain {
  position: relative;
  &.c-image-pattern {
    background: url('../images/bg/pattern.jpg') no-repeat center center;
    background-size: cover;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
  }

  &-small::before {
    padding-top: 77.5%;
  }
  &-medium::before {
    padding-top: 57%;
  }
  &-cover::before {
    padding-top: 48.5%;
  }
  img {
    left: 0;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }
}