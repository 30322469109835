#block-menu-menu-social-menu,
#block-ejn-global-footer-login-social {
  ul {
      display: flex;
      justify-content: center;
      margin-bottom: .75em;
      margin-top: .75em;
      padding: 0;
      @include breakpoint($normal) {
        margin: 0;
      }
      li {
        align-items: center;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        a {
          display: block;
          font-size: 0;
          text-indent: -9999px;
          &::before {
            content: '';
            display: block;
            transition: transform .25s ease-in-out;
          }
          &::after {
            background: radial-gradient(ellipse at center, black 0%, rgba(color(black), 0) 70%);
            bottom: -.25rem;
            content: '';
            display: block;
            height: .75rem;
            left: -15%;
            opacity: 0;
            position: absolute;
            transition: opacity .25s ease-in-out;
            width: 130%;
          }
          &:hover {
            &::after {
              opacity: .15;
            }
            &::before {
              transform: translateY(-.25rem);
            }
          }
          // Social icon.
          &[href*=facebook] {
            &::before {
              @include sprite--social-fb-small;
            }
          }
          &[href*=instagram] {
            &::before {
              @include sprite--social-inst-small;
            }
          }
          &[href*=twitter],
          &[href*=youtube] {
            &::before {
              @include sprite--social-tw-small;
            }
          }
          &[href*=pinterest] {
            &::before {
              @include sprite--social-pint-small;
            }
          }
          &[href*=flickr] {
            &::before {
              @include sprite--social-flickr-small;
            }
          }
          &[href*=linkedin] {
            &::before {
              @include sprite--social-link-small;
            }
          }
        }
      }
    }
  }
