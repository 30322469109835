#block-ejn-global-twitter-block {
  h2 {
    margin: 0 0 .25em;
    text-align: center;
    .title-0 {
      color: color(gray);
    }
  }
  .slick-slider {
    padding: 0;
    @include breakpoint($medium) {
      padding: 0 2em;
    }
    .slick-track {
      display: flex;
    }
  }
  .slick-slide {
    @include boxShadow;
    background-color: color(white);
    display: flex;
    flex-direction: column;
    height: auto;
    margin: .5em;
    padding: 1em 1.5em;
    a {
      text-decoration: none;
    }
    span {
      display: block;
    }
    .twitter-user,
    .twitter-date {
      @include font(oswald);
      text-align: center;
      a {
        color: color(gray);
      }
    }
    .twitter-date {
      margin-top: auto;
    }
    .twitter-text {
      line-height: 1.3;
      margin: .5em 0;
    }
  }
}