.node-profile {

  // Page.
  &.view-mode-full {
    .group-column {
      border-bottom: 1px solid color(gray-light);
      margin-bottom: 1em;
      padding-bottom: 1em;
      @include breakpoint($medium) {
        display: flex;
      }
      @include infoIcons();
      .field-label {
        @include font(oswald, 600);
        color: color(blue);
        font-size: $font-medium;
      }
      .field-group-div {
        flex: 1;
        & + .field-group-div {
          border-top: 1px solid color(gray-light);
          margin: .75em 0 0;
          padding: .75em 0 0;
          @include breakpoint($medium) {
            border-left: 1px solid color(gray-light);
            border-top: 0;
            margin: 0 0 0 1.5em;
            padding: 0 0 0 1.5em;
          }
        }
        &.group-left {
          .field {
            display: flex;
            padding: 0.5em 0;
            & + .field {
              border-top: 1px solid color(gray-light);
            }
            & > div {
              flex: 1;
            }
          }
        }
      }
    }
    .field-name-body {
      margin-bottom: 2em;
      .field-label {
        @include font(oswald, 600);
        color: color(blue);
        font-size: $font-medium;
      }
    }
  }

  // Teaser.
  &.view-mode-teaser,
  &.view-mode-country_guide {
    @include boxShadow(large);
    background-color: color(white);
    border-radius: .75em;
    display: flex;
    overflow: hidden;
    position: relative;
    transition: box-shadow .25s ease-in-out;
    &:hover {
      @include boxShadowHover(large);
      .group-info .field-name-title a {
        color: color(blue-dark);
      }
    }
    .group-image {
      border-right: 1px solid darken(color(gray-lighter), 7%);
      flex: 1;
      img {
        display: block;
      }
    }
    .field-member-flag {
      @include memberFlag;
      & + h3 {
        padding-right: 1.5em;
      }
    }
    .group-info {
      flex: 1;
      padding: .75em 1em;
      .field-name-title {
        a {
          @include font(oswald, 600);
          color: color(blue);
          text-decoration: none;
          &::before {
            @include blockLink;
          }
        }
      }
      .field-name-field-location {
        @include font(oswald, 600);
      }
      .field-name-field-months {
        @include font(oswald);
        margin-bottom: 1em;
        .field-item {
          display: inline-block;
          & + .field-item {
            &::before {
              content: ' - ';
            }
          }
        }
      }
    }
    .field-name-node-link {
      a {
        @include btnReadMore;
        position: relative;
      }
    }
  }

  // Header.
  &.view-mode-header {
    align-items: center;
    display: flex;
    line-height: 1.3;
    margin: -1.5em -1em 2.5em;
    min-height: 2.5em;
    @include breakpoint($medium) {
      margin: -1.5em 0 2.5em;
    }
    .field-name-title {
      flex-grow: 1;
      .field-item {
        align-items: center;
        @include breakpoint($medium) {
          display: flex;
        }
        .field-member-flag {
          display: none;
        }
        .organised-by {
          margin-right: .25em;
        }
        .node-type {
          @include font(oswald, 600);
          display: block;
          font-size: $font-medium;
          margin-right: auto;
          text-transform: uppercase;
          @include breakpoint($medium) {
            display: inline;
          }
        }
        h2 {
          @include font(oswald, 600);
          display: inline-block;
          font-size: $font-medium;
          margin: 0;
          text-transform: none;
          @include breakpoint($medium) {
            display: block;
          }
        }
      }
    }
    .field-name-field-logo {
      margin-left: auto;
      padding-left: .75em;
      img {
        max-height: 50px;
        width: auto;
      }
    }
  }

}
