#breadcrumb {
  display: none;
  @include breakpoint($normal) {
    background-color: color(white);
    display: block;
  }
  .breadcrumb {
    @include maxWidth;
    font-size: $font-small;
    padding: .5em 0;
    a {
      color: color(gray);
      &:hover {
        color: color(orange);
      }
    }
  }
}