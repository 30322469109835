#block-ejn-global-join-ejn {
  background: linear-gradient(145deg, $gradient-join);
  position: relative;
  .content {
    @include maxWidth;
    padding: 2em 0;
    text-align: center;
    #block-join-ejn-head-logo {
      margin: 0 auto 1em;
      width: 95%;
      svg {
        max-width: 100%;
      }
    }
    .block-body {
      margin-bottom: 1.5em;
      margin-top: 1.5em;
      position: relative;
      & > div {
        color: color(white);
        line-height: 1.1;
        padding: .75em;
        text-align: center;
      }
    }
    // Special text style.
    .h-text-special {
      &1 {
        @include font(oswald, 300);
        font-size: $font-medium;
      }
      &2 {
        @include font(oswald);
        font-size: $font-medium;
      }
      &3 {
        @include font(oswald, 600);
        font-size: $font-discrete;
        text-transform: uppercase;
      }
    }
  }
}

// Front page specific style
.front {
  #block-ejn-global-join-ejn {
    #block-join-ejn-head-logo {
      height: 170px;
      padding: 0;
      width: 390px;
      max-width: 95%;
    }
    .block-body {
      margin-bottom: 2.5em;
      margin-top: 2.5em;
      @include breakpoint($medium) {
        display: flex;
        margin-bottom: 3.5em;
        margin-top: 3.5em;
      }
      & > div {
        flex: 1;
        span {
          display: block;
        }
      }
    }
    .block-graphic {
      @include breakpoint($normal) {
        .graphic-element {
          position: absolute;
          opacity: .2;
          &.graphic-round {
            background: linear-gradient(40deg, rgba(color(blue-darker), 0), color(blue-darker));
            border-radius: 50%;
            bottom: -5em;
            height: 30em;
            width: 30em;
            left: 5%;
          }
          &.graphic-rectangle {
            background: linear-gradient(color(blue-darker), rgba(color(blue-darker), 0));
            height: 30em;
            right: 10%;
            top: 2em;
            transform: rotate(25deg);
            width: 15em;
          }
        }
      }
    }
    // Special text style.
    .h-text-special {
      &1 {
        @include font(oswald, 300);
        font-size: $font-large;
      }
      &2 {
        @include font(oswald);
        font-size: $font-xlarge;
      }
      &3 {
        @include font(oswald, 600);
        font-size: $font-xlarge;
        text-transform: uppercase;
      }
    }
  }
}

// Sidebar specific style.
.sidebar {
  #block-ejn-global-join-ejn {
    .button a {
      font-size: $font-medium;
      padding: .5em 1em;
    }
    span:last-child {
      display: block;
    }
  }
}