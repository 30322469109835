#block-views-tours-block-3 {
  .c-footer-framed {
    position: relative;
  }
  .slick {
    padding-top: 1em;
    .slick-track {
      display: flex;
      justify-content: center;
    }
    @include breakpoint($medium) {
      margin-bottom: 2em;
    }
  }
}
