#block-views-festivals-restyle-block-1 {
  margin-bottom: 3em;
  margin-top: 3em;
  padding: 3em 0;
  position: relative;
  z-index: 1;
  @include breakpoint($medium) {
    padding: 5em 0;
  }
  h2 {
    margin-bottom: .5em;

  }
  .content {
    @include maxWidth;
    .slick__slider {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .block-graphic {
    @include breakpoint($medium) {
      .graphic-element {
        position: absolute;
        opacity: .2;
        &.graphic-round {
          background: linear-gradient(35deg, rgba(color(blue), 0), color(blue));
          border-radius: 50%;
          content: "";
          display: block;
          height: 35em;
          left: 0;
          margin: 0 auto;
          right: 0;
          top: 65%;
          transform: translateY(-50%);
          width: 35em;
          z-index: -1;
        }
        &.graphic-rectangle {
          background: linear-gradient(color(blue), rgba(color(blue), 0));
          content: '';
          display: block;
          height: 15em;
          left: 0;
          margin: 0 auto;
          right: 0;
          top: 65%;
          transform: rotate(-15deg) translateY(-50%);
          width: 80%;
        }
      }
    }
  }

}