#sliding-popup.sliding-popup-bottom {
  background-color: color(blue);
  .popup-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  #popup-buttons {
    button {
      @include font(roboto, 400);
      background: transparent;
      border-radius: .5em;
      border: 2px solid rgba(color(white), .2);
      box-shadow: none;
      color: color(white);
      font-size: $font-small;
      font-weight: normal;
      margin: 0;
      padding: .25em .75em;
      text-shadow: none;
      transition: background .25s ease-in-out;
      &:hover {
        background: rgba(color(white), .15)
      }
    }
  }
}