a.country-guide-link {
  background: url(/sites/all/modules/features/ejn_country_guides/images/banner_jazzpanorama.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  text-indent: -9999px;
  margin: 0 auto;
  width: 100%;
  p {
    position: absolute;
  }
  img {
    opacity: 0;
    margin: 0 auto;
  }
}
