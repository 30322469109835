#block-views-news-block {
  padding: 2em 0;
  .content {
    @include maxWidth;
    .slick__slider {
      display: flex;
      flex-wrap: wrap;
      .slide {
        width: 25%;
      }
    }
  }
}

#block-views-news-block-5 {
  .slick-track {
    display: flex;
    justify-content: center;
  }
  .slide {
    width: 25%;
  }
  .c-footer-framed {
    position: relative;
  }
}

#block-views-news-block-2 {
  .views-row {
    @include breakpoint($normal) {
      width: 100%;
    }
  }
}
