#block-system-main-menu {
  @include font(oswald);
  text-transform: uppercase;
  &.fixed .menu {
    padding-left: 100px;
  }
  .content > ul {
    margin: 0;
    padding: 0;
    @include breakpoint($normal) {
      display: flex;
      justify-content: center;
    }
    li {
      border-top: 1px solid rgba(color(white), .15);
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      @include breakpoint($normal) {
        border-top: 0;
      }
      a {
        display: block;
        text-decoration: none;
      }
    }
  // First level
  & > li {
    margin: 0 1em;
    position: relative;
    @include breakpoint($normal) {
        // Activate submenu.
        &:hover {
          a + .menu {
            transform: translate(-50%, 0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
      & > a {
        color: color(white);
        display: block;
        overflow: hidden;
        padding: .75em 0;
        position: relative;
        @include breakpoint($normal) {
          &:hover,
          &.active-trail {
            &::after {
              transform: translateY(0);
            }
          }
          &::after {
            background-color: color(orange);
            bottom: 0;
            content: '';
            display: block;
            height: 8px;
            left: 0;
            position: absolute;
            transform: translateY(100%);
            transition: transform .25s ease-in-out;
            width: 100%;
          }
        }
      }

      // Submenu.
      &.expanded {
        margin-left: 1.2em;
        &::before {
          border-color: color(orange) transparent transparent transparent;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          content: '';
          display: block;
          height: 0;
          left: -1em;
          position: absolute;
          top: 1.25em;
          width: 0;
          width: 0;
        }
      }
      .menu {
        border-top: 1px solid rgba(color(white), .15);
        height: 0;
        opacity: 0;
        padding: 0;
        padding-left: .75em;
        visibility: hidden;
        z-index: 5;
        @include breakpoint($normal) {
          background-color: color(white);
          box-shadow: 0 2px 8px rgba(color(black), .3);
          border: 0;
          font-size: $font-small;
          height: auto;
          left: 50%;
          padding-left: 0;
          position: absolute;
          text-align: center;
          transition: all .25s ease-in-out;
          transform: translate(-50%, .25em);
        }
        &.active {
          height: auto;
          opacity: 1;
          visibility: visible;
        }
        li {
          border: 0;
        }
        a {
          color: color(white);
          padding: .5em 0;
          @include breakpoint($normal) {
            color: color(gray);
            padding: .5em 1.25em;
            white-space: nowrap;
          }
          &:hover,
          &.active-trail,
          &.active {
            color: color(orange);
          }
        }
      }
    }

  // Search button.
  #mainnav-search {
    @include breakpoint($normal) {
      margin: 0 .25em;
    }
    & > a {
      align-items: center;
      display: flex !important;
      height: 100%;
      padding: .75em 0;
      @include breakpoint($normal) {
        padding: 0;
        font-size: 0;
      }
      &::before {
        @include sprite--search;
        content: '';
        display: block;
        margin-left: .5em;
        order: 1;
        @include breakpoint($normal) {
          margin-left: 0;
          order: 0;
        }
      }
    }
  }
}
  // Logo fixed
  #mainnav-logo {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform .25s ease-in-out, opacity .25s ease-in-out;
    a {
      background: url('../images/logo-ejn.svg') no-repeat center center;
      background-size: contain;
      height: 50px;
      width: 145px;
      &::after {
        display:none;
      }
    }
    &::before {
      background-color: #fff;
      box-shadow: 5px 5px 10px rgba(0,0,0,.15);
      content: '';
      display: block;
      height: 125%;
      left: -5%;
      position: absolute;
      top: 0;
      transform: rotate(-7deg) translateY(-25%);
      width: 110%;
    }
  }
  &.fixed #mainnav-logo {
    opacity: 1;
    transform: translateY(0);
  }
}