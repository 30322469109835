.js {
  // Front.
  .front {
    #logo {
      opacity: 0;
      transform: translateY(-75%);
    }
    #block-views-news-block-1 {
      .slick__slide {
        opacity: 0;
      }
    }
    .c-block-framed {
      .c-title-frame-graphic,
      .c-footer-frame-graphic {
        visibility: hidden;
      }
    }
  }
}