.field-label-inline {
  align-items: center;
  display: flex;
  .field-label {
    float: none;
    margin-right: .25em;
  }
  .field-items {
    float: none;
  }
}