#block-search-form {
  background-color: darken(color(blue), 5%);
  height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  .content {
    padding: .75em 0;
  }
  .container-inline {
    display: flex;
    align-items: center;
    justify-content: center;
    .form-item {
      margin: 0;
      min-width: 30%;
    }
  }
  .form-text {
    background-color: rgba(color(blue-light), .4);
    border: 2px solid color(blue-light);
    border-radius: .5em;
    color: color(white);
    padding: .35em;
    &:focus {
      border-color: rgba(color(white), .35);
      outline: none;
    }
  }
  .form-submit {
    @include font(oswald);
    background-color: transparent;
    border: 2px solid color(white);
    border-radius: .5em;
    color: color(white);
    flex: 1;
    margin-left: 1em;
    padding: .35em;
    position: relative;
    text-decoration: none;
    z-index: 1;
    text-transform: uppercase;
  }
}