.header-mobile {
  align-items: center;
  background: linear-gradient(to right, color(blue), color(blue-light));
  display: flex;
  left: 0;
  min-height: 4em;
  padding: .75em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @include breakpoint($normal) {
    display: none;
  }
  .btn-open-nav {
    margin-left: auto;
  }
  #block-ejn-global-user-custom-block {
    @include font(oswald);
    display: none;
    text-transform: uppercase;
    @include breakpoint($normal) {
      display: block;
      left: 0;
      position: relative;
    }
    .block-content {
      display: flex;
      .button {
        text-decoration: none;
        border: 2px solid rgba(color(white), .2);
        border-radius: .5em;
        color: color(white);
        padding: .25em .75em;
        &.btn-login {
          background-color: color(white);
          color: color(blue-light);
          margin-left: .5em;
        }
      }
    }
  }
}