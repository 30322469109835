.country-map-wrapper {
  background-color: color(white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 -2px 10px rgba(0,0,0,.1);
  margin-bottom: 1em;
  .country-map {

  }
  .country-text {
    padding: 1.5em 2.25em;
  }
}