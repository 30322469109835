.tabs {
  ul.tabs {
    border: 0;
    margin: 0 0 1em;
    padding: 0;
    li {
      a {
        @include font(oswald);
        background-color: transparent;
        color: color(blue);
        border: 2px solid color(blue);
        border-radius: .5em;
        transition: all .25s ease-in-out;
        &:hover {
          background-color: color(blue);
          color: color(white);
        }
      }
      &.active a {
        cursor: default;
        opacity: .75;
        &:hover {
          background-color: transparent;
          color: color(blue);
        }
      }
    }
  }
}