.node-media-chart {

  // Full.
  &.node-full {
    .field-name-field-paragraphs {
      &> .field-items {
        &> .field-item {
          margin-top: 1em;
          border-top: 1px solid color(gray-light);
          padding-top: 1em;
        }
      }
      h2 {
        font-size: $font-discrete;
        margin-bottom: .35em;
        margin-top: .35em;
        text-transform: none;
      }
      .field-name-field-content-description {
        margin-bottom: 1.5em;
        margin-top: 1.5em;
      }
    }
  }

  // Teaser.
  &.node-teaser {
    padding: .5em;
    position: relative;
    &:hover {
      .group-image {
        @include boxShadow;
      }
      .field-name-title a {
        color: color(blue);
      }
    }
    & > .field-name-title {
      a {
        color: color(gray);
        font-weight: 700;
        text-decoration: none;
        &::before {
          @include blockLink;
        }
      }
    }
    .field-name-post-date,
    .field-name-field-reference h3 {
      font-size: $font-small;
      font-weight: 400;
    }
    .group-author {
      align-items: baseline;
      display: flex;
      .prefix-author {
        font-size: $font-small;
        margin-right: .25em;
      }
    }
  }
}

.paragraphs-item-media-chart-content {
  .content {
    display: flex;
    justify-content: space-between;
    @include breakpoint(0 $medium) {
      flex-direction: column-reverse;
    }

    .group-content {
      max-width: 65%;
      @include breakpoint(0 $medium) {
        max-width: 100%;
        padding: 1em 0;
      }
      .field-name-field-musician-info,
      .field-name-field-links {
        font-style: italic;
      }
      .field-name-field-links {
        .field-items {
          display: flex;
          flex-wrap: wrap;
          .field-item:not(:first-child) {
            margin-left: .2em;
          }
        }
      }
    }
    .field-name-field-featured-image {
      align-self: center;
      width: 34%;
      @include breakpoint(0 $medium) {
        width: 80%;
        margin-bottom: 1em;
      }
      @include breakpoint(0 $small) {
        width: 100%;
      }
    }
  }
}
