// =============================================================================
// Generic.
// =============================================================================
@mixin maxWidth($maxWidth: $width-max) {
  @if $maxWidth == large {
    max-width: $maxwidth-large;
    width: 98%;
  }
  @else {
    max-width: $width-max;
    width: 95%;
  }
  margin-left: auto;
  margin-right: auto;
}

// =============================================================================
// Helpers.
// =============================================================================
// Hide from both screenreaders and browsers: h5bp.com/u
@mixin hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Create full block absolute link
@mixin blockLink {
  &::before {
    bottom: 0;
    content: '';
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

// Get color from map.
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

// Px to em
@function pxem($pixels) {
  @return #{$pixels/16}em;
}

// Clearfix.
@mixin clearfix {
  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

// =============================================================================
// Typography.
// =============================================================================
// Font smoothing.
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    } @else {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
    }
  }

// Font.
@function font($key) {
  @if map-has-key($fonts, $key) {
    @return map-get($fonts, $key);
  }

  @warn "Unknown `#{$key}` in $fonts.";
  @return null;
}

@mixin font($name, $weight:"") {
  @if $name  {
    font-family: font($name);
  }
  @if $weight {
    font-weight: #{$weight};
  }
}

// =============================================================================
// Columns.
// =============================================================================
@mixin col2 {
  padding: .5em;
  width: 100%;
  @include breakpoint($medium) {
    width: 50%;
  }
}
@mixin col3 {
  padding: .5em;
  width: 100%;
  @include breakpoint($small) {
    width: 50%;
  }
  @include breakpoint($medium) {
    width: 33.3%;
  }
}
@mixin col4 {
  padding: .5em;
  width: 100%;
  @include breakpoint($medium) {
    width: 50%;
  }
  @include breakpoint($normal) {
    width: 25%;
  }
}

// =============================================================================
// Graphic.
// =============================================================================
@mixin boxShadow($dimension: '') {
  @if $dimension == large {
    box-shadow: 0 .5rem .65rem rgba(color(black), .15);
    @include breakpoint($medium) {
      box-shadow: 0 .75rem 1rem rgba(color(black), .1);
    }
  }
  @else {
    box-shadow: 0 0 .5rem rgba(color(black), .2);
  }
}

@mixin boxShadowHover($dimension: '') {
  @if $dimension == large {
    box-shadow: 0 .5rem .65rem rgba(color(black), .35);
    @include breakpoint($medium) {
      box-shadow: 0 .75rem 1rem rgba(color(black), .3);
    }
  }
  @else {
    box-shadow: 0 0 .75rem rgba(color(black), .5);
  }
}

@mixin memberFlag {
  @include font(oswald);
  color: color(white);
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
  z-index: 2;
  &::before {
    background-color: color(blue);
    content: '';
    display: block;
    padding-bottom: 150%;
    position: absolute;
    right: -130%;
    top: -30%;
    transform: rotate(45deg);
    width: 300%;
    z-index: -1;
  }
}
@mixin btnReadMore {
  @include font(oswald);
  border: 1px solid color(gray);
  border-radius: .25em;
  color: color(gray);
  display: inline-block;
  font-size: $font-small;
  margin-top: 1.5em;
  padding: .25em 1em;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
  &:hover {
    background-color: darken(color(gray-lighter), 5%);
  }
}
@mixin btnHover {
  transition: box-shadow .25s ease-in-out, transform .25s ease-in-out;
  &:hover {
    box-shadow: 0 .5em .5em rgba(color(black), .15);
    transform: translateY(-.25em);
  }
}

// Create full block absolute link
@mixin blockLink {
  bottom: 0;
  content: '';
  cursor: pointer;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

@mixin horizontalSeparatorTitle {
  display: flex;
  justify-content: space-between;
  &::after {
    border-bottom: 1px solid color(gray-light);
    content: '';
    display: inline-block;
    height: .5em;
    width: 100%;
  }
  &::before {
    border-bottom: 1px solid color(gray-light);
    content: '';
    display: inline-block;
    height: .5em;
    width: 100%;
  }
}


@mixin infoIcons {
  .field-name-field-email,
  .field-name-field-web-link,
  .field-name-field-phone-number,
  .field-name-field-social-links .field-item {
    align-items: center;
    display: flex;
    margin-top: 1em;
    &::before {
      background-repeat: no-repeat;
      background-size: 16px;
      content: '';
      display: inline-block;
      height: 16px;
      margin-right: .5em;
      width: 16px;
    }
    .field-items {
      display: inline-block;
    }
    a {
      color: color(gray);
      text-decoration: none;
      transition: color 200ms ease-in-out;
      &:hover {
        color: color(gray-light);
      }
    }
  }
  .field-name-field-phone-number::before {
    background-image: url('../images/icons/phone.svg');
  }
  .field-name-field-email::before {
    background-image: url('../images/icons/envelop.svg');
  }
  .field-name-field-web-link::before {
    background-image: url('../images/icons/globe.svg');
  }
  .field-name-field-social-links .field-item::before {
    background-image: url('../images/icons/facebook.svg');
  }
  .field-name-field-social-links .field-item {
    &.facebook::before {
      background-image: url('../images/icons/facebook.svg');
    }
    &.twitter::before {
      background-image: url('../images/icons/twitter.svg');
    }
    &.instagram::before {
      background-image: url('../images/icons/instagram.svg');
    }
    &.linkedin::before {
      background-image: url('../images/icons/linkedin.svg');
      background-size: 14px;
    }
    &.youtube::before {
      background-image: url('../images/icons/youtube.svg');
    }
    &.spotify::before {
      background-image: url('../images/icons/spotify.svg');
    }
    &.other::before {
      background-image: url('../images/icons/user.svg');
    }
  }

}
