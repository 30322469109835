// User block.
#block-ejn-global-user-custom-block {
  @include font(oswald);
  display: block;
  position: relative;
  text-transform: uppercase;
  z-index: 30;
  .block-content {
    display: flex;
  }
  .button {
    position: relative;
    text-decoration: none;
    &.btn-login {
      background-color: transparent;
      color: color(blue-light);
      margin: 0 2.5em;
      transition: transform .4s ease-in-out;
      z-index: 1;
      @include breakpoint($normal) {
        &:hover {
          transform: translateY(-.35em);
          &::before {
            box-shadow: 0 .5em .5em rgba(color(black), .25);
          }
        }
        &::before {
          background-color: color(white);
          border-radius: 50%;
          content: '';
          display: block;
          height: 5em;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: box-shadow .4s ease-in-out;
          width: 5em;
          z-index: -1;
        }
      }
    }
    &.btn-register {
      color: color(white);
      border-bottom: 2px dashed color(blue-dark);
      transition: border-color .4s ease-in-out;
      &:hover {
        border-color: color(orange);
        transform: none;
      }
    }
  }
}

// Logged in.
.logged-in {
  #block-ejn-global-user-custom-block,
  #block-ejn-global-footer-login-social {
    .block-content {
      color: color(white);
      flex-wrap: wrap;
      justify-content: flex-end;
      text-align: right;
      span:first-child {
        line-height: 1.2;
        margin-bottom: .25em;
        text-transform: capitalize;
        width: 100%;
      }
      .btn-white {
        @include font(oswald);
        background-color: color(white);
        border-radius: .5em;
        color: color(blue);
        display: inline-block;
        padding: .25em .5em;
        text-decoration: none;
        text-transform: uppercase;
      }
      .btn-account {
        @include font(oswald);
        background-color: color(white);
        border: 2px solid color(white);
        border-radius: .5em;
        color: color(blue);
        display: inline-block;
        margin-right: .5em;
        padding: .25em .5em;
        text-decoration: none;
        text-transform: uppercase;
      }
      .btn-logout {
        @include font(oswald);
        border-radius: .5em;
        color: color(white);
        border: 2px solid rgba(color(white), .5);
        display: inline-block;
        padding: .25em .5em;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

// User admin.
#block-menu-menu-ejn-admin-menu,
#block-ejn-users-user-admin-menu {
  h2 {
    margin: 0 0 .5em;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: .25em;
    position: relative;
    &::before {
      display: none;
    }
    & + li {
      border-top: 1px solid rgba(color(gray), .25);
    }
    a {
      flex-grow: 1;
      margin: 0;
      padding: .25em;
      position: initial;
      text-align: left;
      z-index: 5;
      &::after {
        display: none;
      }
      &::before {
        right: 0;
        display: none;
      }
    }
    .btn-user {
      border-radius: .5em;
      border: 2px solid color(blue);
      color: color(blue);
      flex-grow: 0;
      padding: .25em .75em;
      text-transform: uppercase;
      transition: all .25s ease-in-out;
      z-index: 2;
      &:hover {
        background-color: color(blue);
        color: color(white);
      }
    }
    .active + .btn-user {
      background-color: color(white);
      &:hover {
        color: color(blue);
      }
    }
    &.active {
      background-color: color(blue);
      a {
        color: color(white);
      }
      .btn-user {
        border-color: color(white);
        color: color(blue);
        &.active {
          color: color(white);
        }
      }
    }
  }
}