.view-grid {
  &-2col {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -.5em;
      .views-row {
        @include col2;
        .views-view-fields,
        .views-field,
        .field-content,
        .node {
          height: 100%;
        }
      }
    }
  }

  &-3col {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -.5em;
      .views-row {
        @include col3;
        .views-view-fields,
        .views-field,
        .field-content,
        .node {
          height: 100%;
        }
      }
    }
  }

  &-4col {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -.5em;
      .views-row {
        @include col4;
        .views-view-fields,
        .views-field,
        .field-content,
        .node {
          height: 100%;
        }
      }
    }
  }

}