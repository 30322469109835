.page-calendar {
  .btn-blue {
    float: right;
    margin-right: 1em;
  }

  .region-content {
    clear: both;
  }

  #block-system-main {
    > .content {
      position: relative;
    }
  }

  .map-header {
    position: absolute;
    top: 0;
    left: 1em;
  }

  #map-calendar-wrapper {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($normal) {
      flex-wrap: wrap;
    }

    #calendar-map {
      flex: 100%;
      margin: 0;
      margin-left: 1em;
      margin-right: 1em;

      @include breakpoint($normal) {
        flex: 1;
      }
    }

    #calendar-wrapper {
      flex: 100%;
      margin-left: 1em;
      margin-right: 1em;
      margin-top: 1.5em;
      max-width: 100%;

      @include breakpoint($normal) {
        flex: 1;
        margin-top: 0;
      }

      .fullcalendar {
        .fc-event-container {
          z-index: 4 !important;
        }

        .fc-day {
          &.fc-first > div {
            min-height: auto !important;
          }
        }
      }
    }
  }
}
