.view-general-assembly-reports {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.75%;
    .views-row {
      @include boxShadow(large);
      background-color: color(white);
      margin: .75%;
      text-align: center;
      width: 31.8%;
      img {
        display: block;
      }
      h4 {
        margin: 0;
        padding: .75em;
        a {
          @include font(oswald, 600);
          color: color(gray);
          text-decoration: none;
        }
      }
    }
  }
}