.more-link {
  text-align: center;
  a {
    @include font(oswald);
    background-color: color(blue);
    border-radius: .5em;
    color: color(white);
    display: inline-block;
    padding: .5em 2em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;
    @include btnHover;
  }
}
.newsletter-button {
  @include font(oswald);
  align-items: center;
  color: color(white);
  display: flex;
  font-size: $font-small;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &::before {
    @include sprite--newsletter;
    content: '';
    display: block;
    transition: transform .25s ease-in-out;
  }
  &::after {
    background: radial-gradient(ellipse at center, black 0%, rgba(color(black), 0) 70%);
    bottom: -.25rem;
    content: '';
    display: block;
    height: .75rem;
    left: -.5em;
    opacity: 0;
    position: absolute;
    transition: opacity .25s ease-in-out;
    width: 3em;
  }
  &:hover {
    &::after {
      opacity: .15;
    }
    &::before {
      transform: translateY(-.25rem);
    }
  }
}

.btn-yellow {
  position: relative;
  z-index: 1;
  a {
    @include font(oswald, 600);
    background-color: color(orange);
    border-radius: .5em;
    color: color(white);
    display: inline-block;
    font-size: $font-medium;
    line-height: 1.1;
    margin: .5em;
    padding: .5em 2em;
    position: relative;
    text-decoration: none;
    transition: text-shadow .05s ease-in-out;
    @include breakpoint($medium) {
      font-size: $font-discrete;
    }
    &:hover {
      text-shadow: .05em .05em 0 rgba(color(black), .15);
      top: 1px;
      &::before {
        top: 3px;
      }
    }
    &::before {
      background-color: darken(color(orange), 15%);
      border-radius: .5em;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 4px;
      width: 100%;
      z-index: -1;
    }
  }
}
// Back btn.
.btn-back,
.btn-blue {
  @include font(oswald);
  align-items: center;
  background-color: color(blue);
  border-radius: .25em;
  color: color(white);
  display: inline-flex;
  font-size: $font-small;
  margin-bottom: 1em;
  margin-left: .5em;
  margin-top: 1em;
  padding: .25em .75em .25em 0;
  position: relative;
  text-decoration: none;
  @include breakpoint($normal) {
    margin-top: 0;
  }
  &::after {
    background: url('../images/elements/btn-back.svg');
    content: '';
    display: block;
    height: 100%;
    left: 5px;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    width: 1em;
  }
  &::before {
    @include sprite--arrow-sx;
    content: '';
    display: block;
    margin-right: .25em;
  }
}
.btn-blue {
  padding: 0.5em 1em;
  &::after {
    display: none;
  }
  &::before {
    display: none;
  }
}

// Open nav btn.
.btn-open-nav {
  background-color: color(blue-dark);
  border-radius: .5em;
  border: 0;
  display: block;
  height: 2.5em;
  left: 0;
  padding: .5em;
  width: 2.5em;
  span {
    margin-top: -2px;
    top: 50%;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &,
    &::before,
    &::after {
      background-color: color(white);
      display: block;
      height: 3px;
      position: absolute;
      transition-duration: 0.15s;
      transition-property: transform;
      transition-timing-function: ease;
      width: 1.5em;
    }
    &::after {
      bottom: -7px;
      content: "";
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::before {
      content: "";
      top: -7px;
      transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    }
  }
}

// Filters btn.
.btn-filter {
  @include font(oswald);
  background-color: color(white);
  border: 1px solid color(blue);
  color: color(blue);
  display: block;
  overflow: hidden;
  padding: .5em 1.75em;
  position: relative;
  text-align: left;
  width: 100%;
  &::before {
    border-color: color(orange) transparent transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    content: '';
    display: block;
    height: 0;
    left: .5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
  &.h-open {
    &::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .btn-hide {
    left: .5em;
    opacity: 0;
    position: absolute;
    transform: translateY(100%);
  }
}
