.map-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 .5em;
}
.map-wrapper {
  clear: both;
  height: 300px;
  margin-bottom: 2em;
  margin-top: 1.5em;
  @include breakpoint($medium) {
    height: 400px;
  }
  @include breakpoint($normal) {
    height: 600px;
  }
}
.switch-container {
  border: 1px solid color(gray-light);
  position: relative;
  input {
    display: none;
  }
  .switch-wrapper {
    display: flex;
    padding: 0;
    label {
      @include font(oswald);
      align-items: center;
      display: flex;
      color: color(gray);
      cursor: pointer;
      font-size: 1rem;
      font-style: normal;
      list-style: none;
      padding: .25em 1em .25em .75em;
      &:before {
        content: '';
        display: block;
        margin-right: .25em;
      }
      &[for=state-calendar]::before {
        @include sprite--grid;
      }
      &[for=state-grid]::before {
        @include sprite--grid;
      }
      &[for=state-map]::before {
        @include sprite--map;
      }
      &.h-active {
        color: color(orange);
      }
    }
  }
}
img[src="sites/all/themes/custom/sixtheme/dist/images/icons/map-pin.svg"] {
  transform: translate(0, 1em);
}
