.c-block-framed {
  position: relative;
  // Title
  .c-title-framed {
    @include maxWidth;
    display: flex;
    pointer-events: none;
    h2 {
      padding: 0 .5em;
    }
    .c-title-frame {
      height: 2em;
      margin-top: .5em;
      position: relative;
      transform: translateY(50%);
      flex-grow: 1;
      .c-title-frame-graphic {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-top: 5px solid color(blue);
      }
      &-left {
        margin-left: 10%;
        .c-title-frame-graphic {
          border-left: 5px solid color(blue);
          right: 0;
        }
      }
      &-right {
        margin-right: 10%;
        .c-title-frame-graphic {
          border-right: 5px solid color(blue);
          left: 0;
        }
      }
    }
  }

  // Footer
  .c-footer-framed {
    @include maxWidth;
    bottom: 1em;
    display: flex;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    .c-footer-space {
      min-width: 12em;
    }
    .c-footer-frame {
      height: 4em;
      margin-top: .5em;
      position: relative;
      width: 100%;
      .c-footer-frame-graphic {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-bottom: 5px solid color(blue);
      }
      &-left {
        margin-left: 10%;
        .c-footer-frame-graphic {
          border-left: 5px solid color(blue);
          left: 0;
        }
      }
      &-right {
        margin-right: 10%;
        .c-footer-frame-graphic {
          border-right: 5px solid color(blue);
          right: 0;
        }
      }
    }
  }

}