.node-club {

  // Page.
  .group-column {
    border-bottom: 1px solid color(gray-light);
    margin-bottom: 1em;
    padding-bottom: 1em;
    @include breakpoint($medium) {
      display: flex;
    }
    @include infoIcons();
    .field-label {
      @include font(oswald, 600);
      color: color(blue);
      font-size: $font-medium;
    }
    .field-group-div {
      flex: 1;
      & + .field-group-div {
        border-top: 1px solid color(gray-light);
        margin: .75em 0 0;
        padding: .75em 0 0;
        @include breakpoint($medium) {
          border-left: 1px solid color(gray-light);
          border-top: 0;
          margin: 0 0 0 1.5em;
          padding: 0 0 0 1.5em;
        }
      }
      &.group-left {
        .field {
          display: flex;
          padding: 0.5em 0;
          & + .field {
            border-top: 1px solid color(gray-light);
          }
          & > div {
            flex: 1;
          }
        }
      }
    }
  }
  .field-name-body {
    margin-bottom: 2em;
    .field-label {
      @include font(oswald, 600);
      color: color(blue);
      font-size: $font-medium;
    }
  }

  // Teaser.
  &.view-mode-teaser,
  &.view-mode-country_guide {
    @include boxShadow(large);
    background-color: color(white);
    border-radius: .75em;
    display: flex;
    overflow: hidden;
    position: relative;
    .group-image {
      align-items: center;
      border-right: 1px solid darken(color(gray-lighter), 7%);
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .group-info {
      flex: 1.1;
      padding: .75em 1em;
      .field-name-title {
        a {
          @include font(oswald, 600);
          color: color(blue);
          text-decoration: none;
        }
      }
      .field-name-field-location {
        @include font(oswald, 600);
      }
      .field-name-field-months {
        @include font(oswald);
        margin-bottom: 1em;
        .field-item {
          display: inline-block;
          & + .field-item {
            &::before {
              content: ' - ';
            }
          }
        }
      }
    }
  }

  // Slide.
  &.view-mode-slide {
    @include boxShadow(large);
    background-color: color(white);
    border-radius: .75em;
    display: flex;
    margin: 1em 0;
    overflow: hidden;
    position: relative;
    .c-image-contain {
      flex: 1;
    }
    .group-info {
      flex: 1;
      padding: .75em 1em;
      .field-name-title {
        a {
          @include font(oswald, 600);
          color: color(blue);
          text-decoration: none;
        }
      }
      .field-name-field-location {
        @include font(oswald, 600);
      }
      .field-name-field-months {
        @include font(oswald);
        margin-bottom: 1em;
        .field-item {
          display: inline-block;
          & + .field-item {
            &::before {
              content: ' - ';
            }
          }
        }
      }
    }
  }

  // Teaser&slide.
  &.view-mode-teaser,
  &.view-mode-country_guide,
  &.view-mode-slide {
    position: relative;
    transition: box-shadow .25s ease-in-out;
    &:hover {
      @include boxShadowHover(large);
      .group-info .field-name-title a {
        color: color(blue-dark);
      }
    }
    .group-info .field-name-title a {
      &::before {
        @include blockLink;
      }
    }
  // Flag member.
  .field-member-flag {
    @include memberFlag;
    & + h3 {
      padding-right: 1.5em;
    }
  }
    // Read more.
    .field-name-node-link {
      a {
        @include btnReadMore;
        position: relative;
      }
    }
  }

  // Header.
  &.view-mode-header {
    align-items: center;
    display: flex;
    margin: -1.5em 0 2.5em;
    min-height: 2.5em;
    .field-name-title {
      flex-grow: 1;
      .field-item {
        align-items: center;
        display: flex;
        .field-member-flag {
          display: none;
        }
        .node-type {
          @include font(oswald, 600);
          font-size: $font-medium;
          margin-right: auto;
          text-transform: uppercase;
        }
        h2 {
          @include font(oswald, 600);
          font-size: $font-medium;
          margin: 0 .25em;
          text-transform: none;
        }
      }
    }
    .field-name-field-logo {
      margin-left: auto;
      padding-left: .75em;
    }
  }

}
