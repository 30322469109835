@mixin svg-common {
    background-image: url("../images/sprite.svg");
    background-repeat: no-repeat;
}

@mixin sprite--arrow-dx {
    @include svg-common;
    background-position: 79.06976744186046% 95.29025191675794%;
    width: 19.5px;
    height: 25.7px;
}

@mixin sprite--arrow-select {
    @include svg-common;
    background-position: 61.368209255533195% 34.33922996878252%;
    width: 27.6px;
    height: 20.9px;
}

@mixin sprite--arrow-sx {
    @include svg-common;
    background-position: 97.20930232558139% 95.29025191675794%;
    width: 19.5px;
    height: 25.7px;
}

@mixin sprite--grid {
    @include svg-common;
    background-position: 64.70588235294117% 59.21052631578947%;
    width: 25px;
    height: 25.8px;
}

@mixin sprite--grid-blue {
    @include svg-common;
    background-position: 58.8235294117647% 95.39473684210526%;
    width: 25px;
    height: 25.8px;
}

@mixin sprite--map {
    @include svg-common;
    background-position: 0 100%;
    width: 30px;
    height: 30px;
}

@mixin sprite--map-blue {
    @include svg-common;
    background-position: 30.927835051546392% 100%;
    width: 30px;
    height: 30px;
}

@mixin sprite--newsletter {
    @include svg-common;
    background-position: 98.53249475890985% 67.24700761697497%;
    width: 31.6px;
    height: 25.1px;
}

@mixin sprite--search {
    @include svg-common;
    background-position: 33.333333333333336% 23.56902356902357%;
    width: 28px;
    height: 27.9px;
}

@mixin sprite--social-fb-small {
    @include svg-common;
    background-position: 0 25%;
    width: 33px;
    height: 33px;
}

@mixin sprite--social-flickr-small {
    @include svg-common;
    background-position: 64.8936170212766% 0;
    width: 33px;
    height: 33px;
}

@mixin sprite--social-inst-small {
    @include svg-common;
    background-position: 0 64.28571428571429%;
    width: 33px;
    height: 33px;
}

@mixin sprite--social-link-small {
    @include svg-common;
    background-position: 35.1063829787234% 64.28571428571429%;
    width: 33px;
    height: 33px;
}

@mixin sprite--social-pint-small {
    @include svg-common;
    background-position: 100% 0;
    width: 33px;
    height: 33px;
}

@mixin sprite--social-tw-small {
    @include svg-common;
    background-position: 100% 37.414965986394556%;
    width: 33px;
    height: 28.8px;
}

@mixin sprite--title-dec {
    @include svg-common;
    background-position: 0 0;
    width: 61px;
    height: 21px;
}

