.node-project {
  // Slide & teaser - Generic.
  &.view-mode-teaser,
  &.view-mode-slide {
    @include boxShadow(large);
    background-color: color(white);
    position: relative;
    text-align: center;
    transition: box-shadow .25s ease-in-out;
    &:hover {
      @include boxShadowHover(large);
      h3 a {
        color: color(blue-dark);
      }
    }
    img {
      display: block;
      max-width: 100%;
    }
    p {
      margin: 0;
    }
    h3 {
      margin: 0;
      padding: .75em .75em 0;
      a {
        @include font(oswald, 600);
        color: color(blue);
        text-decoration: none;
        &::before {
          @include blockLink;
        }
      }
    }
    .field-name-body {
      @include font(oswald);
      padding: 0 .75em 1em;
    }

    // TEMP.
    // @TODO: cleanup markup body
    strong {
      font-weight: normal;
    }
  }
  // Promoted.
  &.promoted-activity {
    .c-image-contain::before {
      padding-top: 28.5%;
    }
  }
  // Slide.
  &.view-mode-slide {
    margin: .75em 0;
  }
}
