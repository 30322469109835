ul.pager {
  @include font(oswald);
  margin: 2em 0;
  li {
    margin: 0;
    padding: 0;
    &.pager-current {
      background-color: color(blue);
      color: color(white);
      font-weight: normal;
      border-radius: .25em;
      padding: .25em .75em;
    }
  }
  a {
    color: color(gray);
    text-decoration: none;
    &:hover {
      color: color(blue);
    }
  }
}