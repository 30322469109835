.not-front.page-node.node-type-case-history {
  .region-content {
    overflow: initial;
    position: relative;
  }
}
.node-case-history {
  &.view-mode-teaser {
    @include font(oswald);
    background-color: color(white);
    .field-name-title {
      padding-bottom: 1em;
      padding-left: 1em;
      padding-top: 1em;
      a {
        color: color(gray);
        display: block;
        text-decoration: none;
        &:hover {
          color: color(blue);
        }
      }
    }
    .field-name-field-text {
      padding-bottom: 1em;
      padding-left: 1em;
      padding-right: 0.8em;
    }
  }
  &.view-mode-slide {
    @include boxShadow(large);
    background-color: color(white);
    position: relative;
    text-align: center;
    transition: box-shadow .25s ease-in-out;
    margin: .75em 0;
    &:hover {
      @include boxShadowHover(large);
      h3 a {
        color: color(blue-dark);
      }
    }
    img {
      display: block;
      max-width: 100%;
    }
    p {
      margin: 0;
    }
    h3 {
      margin: 0;
      padding: .75em .75em .50em;
      a {
        @include font(oswald, 600);
        color: color(blue);
        text-decoration: none;
        &::before {
          @include blockLink;
        }
      }
    }
    .field-name-field-text {
      @include font(oswald);
      padding: 0 .75em 1em;
    }

    // TEMP.
    // @TODO: cleanup markup body
    strong {
      font-weight: normal;
    }
  }
  &.node-full {
    .content > h2 {
      margin-bottom: .5em;
      margin-top: 0;
    }
    .paragraphs-item-audio {
      margin-bottom: 1em;
      margin-top: 1em;
      h2 {
        @include horizontalSeparatorTitle;
        font-size: 1.75em;
        margin-bottom: .5em;
        span {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
    .paragraphs-item-images {
      margin-bottom: 1.5em;
    }
    .paragraphs-item-text-area {
      @include font(lato);
      font-weight: 300;
      margin-top: 1em;
      strong {
        @include font(oswald);
        color: color(blue);
        font-weight: 600;
      }
    }
    .paragraphs-item-video {
      margin-bottom: 1.5em;
      h2 {
        @include horizontalSeparatorTitle;
        font-size: 1.75em;
        margin-bottom: .5em;
        span {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
      .player {
        height: 0;
        padding-bottom: 56.25%;
        padding-top: 25px;
        position: relative;
      }
      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
    .field-name-field-profile {
      position: absolute;
      top: -3.6em;
      right: 0;
    }
    .node-profile {
      &.view-mode-header {
        @include breakpoint($medium) {
          margin: 0;
        }
      }
    }
    .node-profile.view-mode-header{
      @include breakpoint($medium) {
        margin: 0
      }
    }
  }

}
