.slick,
.slick-slider {
  position: relative;
  .slick__slider {
    @include breakpoint($medium) {
      padding: 0 2em;
    }
  }
  .slick__slide {
    padding: .5em;
  }
  img {
    // width: 100%;
  }
  .slick__arrow:not(.element-hidden) {
    display: none;
    @include breakpoint($medium) {
      display: block;
    }
  }
  .slick-arrow {
    align-content: center;
    background-color: color(blue);
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 0;
    height: 2rem;
    justify-content: center;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: box-shadow .25s ease-in-out, transform .25s ease-in-out;
    width: 2rem;
    &:hover {
      box-shadow: 0 .5em .5em rgba(color(black), .15);
      transform: translateY(-60%);
    }
    &::before {
      content: '';
      display: inline-block;
    }
    &.slick-prev {
      left: -.5rem;
      &::before {
        @include sprite--arrow-sx;
      }
    }
    &.slick-next {
      right: -.5rem;
      &::before {
        @include sprite--arrow-dx;
      }
    }
  }
  .slick-dots {
    font-size: 0;
    margin: 1rem auto;
    padding: 0;
    text-align: center;
    li {
      cursor: pointer;
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: .5rem .25rem;
      button {
        background-color: color(gray);
        border: 0;
        cursor: pointer;
        height: 4px;
        padding: 0;
        text-indent: -9999px;
        width: 12px;
      }
      &.slick-active button {
        background-color: color(blue);
      }
    }
  }
}