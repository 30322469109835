.views-exposed-form {
  margin: 1em .5em 0;
  @include breakpoint($medium) {
    margin: 1em -.75%;
    .btn-filter {
      display: none;
    }
  }
  // Mobile filters.
  .btn-filter.h-open + .views-exposed-widgets {
    max-height: 50em;
    opacity: 1;
  }
  .views-exposed-widgets {
    border: 1px solid color(blue);
    border-top: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out, opacity .25s ease-in-out;
    @include breakpoint($medium) {
      border: 0;
      display: flex;
      max-height: none;
      opacity: 1;
      overflow: initial;
    }
    .views-exposed-widget {
      float: none;
      flex-grow: 1;
      padding: 0;
      margin: 1em;
      @include breakpoint($medium) {
        margin: .75%;
      }
      &.views-submit-button {
        display: none;
      }
    }
    .form-item {
      float: none;
      width: 100%;
      .date-padding {
        float: none;
        input {
          width: 100%;
        }
      }
      &.form-type-select {
        position: relative;
        &::after {
          @include sprite--arrow-select;
          bottom: -.25em;
          content: '';
          display: block;
          pointer-events: none;
          position: absolute;
          right: .5em;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// Admin.
.page-ejn-admin {
  .views-exposed-widgets {
    flex-wrap: wrap;
  }
  .views-reset-button,
  .views-submit-button {
    display: block !important;
    flex-grow: 0 !important;
    input {
      color: color(blue);
      cursor: pointer;
      text-transform: uppercase;
      @include btnHover;
    }
  }
}