.view-case-histories {
  margin-bottom: 2em;
  // Slick.
  .slick {
    padding-top: 1em;
    @include breakpoint($medium) {
      margin-bottom: 2em;
    }
  }
}
#block-views-case-histories-block-5 {
  margin-top: 2em;
}