  .view-clubs-restyle {

  // Slick
  .slick {
    margin-bottom: 2em;
    .slide {
      width: 50%;
    }
  }

}