.view-country-guides {
  .view-content {
    @include breakpoint($medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .views-row {
    @include col4;
    .views-view-fields {
      @include boxShadow;
      background-color: color(white);
      border-radius: .5em;
    }
    a {
      @include font(oswald, 600);
      display: block;
      font-size: $font-medium;
      padding: .75em 1em;
      text-decoration: none;
      &:hover {
        color: color(blue-dark);
      }
    }
  }
}
