.section {
  input,
  select {
    @include font(oswald);
    background-color: transparent;
    border-radius: .25em;
    border: 1px solid color(blue);
    color: color(gray);
    padding: .25em .5em;
    width: 100%;
  }
  input {
    box-shadow: none;
  }

  select {
    appearance: none;
  }

  label {
    font-style: italic;
    font-weight: 400;
  }
  .form-wrapper,
  .form-item {
    clear: both;
  }
  .form-type-checkbox {
    align-items: center;
    display: flex;
    .form-checkbox {
      margin-right: .5em;
      width: auto;
    }
  }
  .form-type-radio {
    align-items: center;
    display: flex;
    .form-radio {
      margin-right: .5em;
      width: auto;
    }
  }
}

// Search form.
.search-form {
  border-bottom: 1px solid color(gray-light);
  padding-bottom: .5em;
  @include breakpoint($normal) {
    margin-top: -1.5em;
    padding-bottom: 1em;
  }
  .form-wrapper {
    @include breakpoint($medium) {
      align-items: center;
      display: flex;
    }
  }
  .form-item {
    display: block;
    @include breakpoint($medium) {
      align-items: center;
      display: flex;
      flex-grow: 1;
    }
    label {
      @include font(oswald);
      color: lighten(color(gray), 20%);
      font-size: $font-medium;
      font-style: normal;
    }
    .form-text {
      flex-grow: 1;
      margin-top: .75em;
      width: auto;
      @include breakpoint($medium) {
        margin: 0 1em;
      }
    }
  }
  .form-submit {
    background-color: color(blue);
    color: color(white);
    padding: .25em 1em;
    width: auto;
  }
}

// Social field.
.field-widget-social-profile-field-default {
  .form-item {
    background: transparent !important;
  }
  .edit-field-social-profile-url {
    margin: 0 !important;
  }
}
