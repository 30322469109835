.node-country-content {

  // Teaser.
  &.view-mode-full {
    @include boxShadow(large);
    background-color: color(white);
    border-radius: .5em;
    overflow: hidden;
    padding: 1rem;
    position: relative;
    text-align: center;
    h3 {
      @include font(oswald, 600);
      color: color(blue);
    }
    .field-name-body {
      margin-bottom: .5em;
      margin-top: .5em;
    }
    .field-name-field-web-link a {
      @include btnReadMore;
      margin: 0;
    }
  }

}
