  .view-festivals-restyle {

  // Slick
  .slick {
    margin-bottom: 2em;
    .slide {
      @include breakpoint($medium) {
        width: 50%;
      }
    }
  }

}