#footer {
  background-color: color(white);
  box-shadow: 0 -2px 10px rgba(color(black), .1);
  margin-top: 2em;
  .section {
    @include breakpoint($normal) {
      @include maxWidth(large);
    }
  }
  .region-footer {
    @include breakpoint($medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .field-type-text-long {
    font-size: $font-xsmall;
    margin: 1.5em 0;
  }
  .field-name-field-member-label {
    align-items: center;
    display: flex;
    margin-bottom: .75em;
    &::after,
    &::before {
      background-color: color(gray);
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      margin: 0 .75em;
      opacity: .25;
    }
  }
  #block-ejn-global-footer-logo-block {
    flex: 2;
    padding: 2em .5em;
    a {
      color: color(gray);
      font-size: $font-xsmall;
      font-weight: 700;
      &:hover {
        color: color(blue);
      }
    }
  }
  #block-ejn-global-footer-member-block {
    flex: 2;
    padding: 2em;
    text-align: center;
    .field-name-field-logo {
      img {
        margin: 0 auto;
      }
    }
    .field-name-field-member {
      .field-items {
        align-items: center;
        display: flex;
        justify-content: center;
        img {
          max-width: 100%;
          padding: .25em .5em;
        }
      }
    }
  }
  #block-ejn-global-footer-login-social {
    align-items: center;
    background-color: color(blue);
    display: flex;
    padding: 2em;
    text-align: center;
    width: 100%;
    @include breakpoint($normal) {
      width: auto;
    }
    .content {
      width: 100%;
      #user-custom-block .block-content {
        display: flex;
        .button {
          @include font(oswald);
          background-color: color(white);
          border: 2px solid rgba(color(white), .2);
          border-radius: .5em;
          color: color(blue);
          flex: 1;
          padding: .35em;
          position: relative;
          text-decoration: none;
          z-index: 1;
          text-transform: uppercase;
          @include btnHover;
          & + .button {
            margin-left: .5em;
          }
          &.btn-register {
            background-color: transparent;
            color: color(white);
          }
        }
      }
      // Social
      ul {
        margin: 2em auto;
      }
    }
    .newsletter-button {
      border-radius: .5em;
      border: 1px solid color(white);
      padding: .75em .5em;
      width: 100%;
      @include btnHover;
    }
  }
}
