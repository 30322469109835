.page-events {
  #content {
    .section {
      display: flex;
      flex-direction: column;
    }
  }
  .btn-blue {
    &.btn-center {
      margin-left: auto;
      margin-right: auto;
    }
    &.btn-left {
      margin-right: auto;
    }
    &.btn-right {
      margin-left: auto;
    }
  }

  .region-content {
    clear: both;
  }
}

.view-events-restyle {

  // Slick
  .slick {
    .slick-track {
      display: flex;
      min-width: 100%;
    }
    .slick__slide {
      height: auto;
      width: 25%;
    }
    .slide__content {
      height: 100%;
    }
  }

  // Elements.
  .more-link {
    margin-top: 2em;
  }
}
