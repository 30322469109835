// ********************
// Breakpoints.
// ********************
$xsmall: 460px;
$small: 640px;
$medium: 768px;
$normal: 1024px;
$large: 1200px;
$full: 1440px;

// ********************
// Dimensions.
// ********************
$maxwidth-large: 1600px;
$width-max: 1300px;

// ********************
// Colors.
// ********************
$colors: (
  // Brand colors.
  blue-lightest: #f0fcff,
  blue-lighter: #A9E8FF,
  blue-light: #34C1F3,
  blue: #0AA5DF,
  blue-dark: #086D92,
  blue-darker: #0E5975,
  orange: #FAB62B,
  red-slate: #F2536F,
  beige: #CDCEA1,

  // Shades of grey.
  white: #FFFFFF,
  gray-lighter: #F9F9F9,
  gray-light: #DDDDDD,
  gray: #5C5C5C,
  gray-dark: #333333,
  black: #000000
);

$gradient-activities: #E8F5FA, #9CDFF9;
$gradient-join: #43C6F4, #1FA7D8;


// ********************
// Typography.
// ********************
@import url('https://fonts.googleapis.com/css?family=Lato:400,900|Oswald:300,400,600,700|Roboto:400,400i,700,700i');
$font-lato: 'Lato', sans-serif;
$font-oswald: 'Oswald', sans-serif;
$font-roboto: 'Roboto', sans-serif;

$fonts: (
  lato: $font-lato,
  oswald: $font-oswald,
  roboto: $font-roboto
);

$font-xsmall: pxem(13);
$font-small: pxem(14);
$font-normal: pxem(16);
$font-medium: pxem(18);
$font-discrete: pxem(24);
$font-large: pxem(32);
$font-xlarge: pxem(38);

